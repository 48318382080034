
import Vue from "vue";
import LiveSearch from "@/components/form-components/LiveSearch.vue";
import rules from "@/services/helpers/validation-rules";
import Comments from "@/modules/orders/components/Comments.vue";

export default Vue.extend({
  name: "ProductsPicker",

  components: { Comments, LiveSearch },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => [] as Array<any>
    },
    comments: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    showComments: {
      required: false,
      type: Boolean,
      default: false
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false
    },
    dense: {
      required: false,
      type: Boolean,
      default: false
    },
    isEdit: {
      required: false,
      type: Boolean,
      default: false
    },
    currency: {
      required: true,
      type: Object
    },
    serviceId: {
      required: true,
      type: Number
    },
    errorMessages: {
      required: false,
      default: () => ({})
    }
  },

  data: () => ({
    rules,
    forceKey: 0 as number,
    selectedCountry: [{ value: null, text: "" }] as Array<any>,
    selectedProduct: [{ value: null, text: "" }] as Array<any>,
    defaultCurrency: "MDL",
    goods: [] as Array<any>,
    firstLoad: false as boolean,
    item: {
      price: "" as string
    } as any,
    exchangeRate: 1 as number,
    totalPriceCurrency: 0 as number,
    totalPrice: 0 as number,
    orderPrice: 0 as number
  }),

  watch: {
    currency: {
      immediate: true,
      handler() {
        for (const item of this.goods) {
          this.setPrice(item);
        }
      }
    },
    "value.details.exchange_rate"() {
      this.exchangeRate = this.value.order.exchange_rate;

      for (const item of this.goods) {
        this.setPrice(item);
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.exchangeRate = this.value.details.exchange_rate;
        this.goods = this.value.details.goods;

        if (!this.firstLoad) {
          this.setLiveSearchValues();
        }
      }
    },
    goods: {
      immediate: true,
      deep: true,
      handler() {
        this.getPrices();
      }
    }
  },

  computed: {
    totalQuantity() {
      return this.goods.reduce(
        (total: number, { quantity }: { quantity: number }) => {
          if (quantity) {
            return Math.floor((total + Number(quantity)) * 100) / 100;
          }
          return Math.floor(total * 100) / 100;
        },
        0
      );
    },
    totalSize() {
      return this.goods.reduce((total: number, { size }: { size: number }) => {
        if (size) {
          return Math.floor((total + Number(size)) * 100) / 100;
        }
        return Math.floor(total * 100) / 100;
      }, 0);
    },
    parentLanguage() {
      return this.value.order.language === "ro";
    }
  },

  mounted() {
    if (!this.isEdit && !this.goods.length) {
      this.addRow();
    }
  },

  methods: {
    getPrices() {
      this.totalPriceCurrency = this.goods.reduce(
        (
          totalPrice: number,
          {
            price_currency,
            quantity
          }: { price_currency: number; quantity: number }
        ) => {
          if (price_currency) {
            return (
              Math.floor(
                (totalPrice + Number(price_currency)) * 100
              ) / 100
            );
          }
          return Math.floor(totalPrice * 100) / 100;
        },
        0
      );

      this.totalPrice = this.goods.reduce(
        (totalPrice: number, { price }: { price: number }) => {
          if (price) {
            return Math.floor((totalPrice + Number(price)) * 100) / 100;
          }
          return Math.floor(totalPrice * 100) / 100;
        },
        0
      );

      this.orderPrice = this.goods.reduce(
        (
          total: number,
          { quantity, price }: { quantity: number; price: number }
        ) => {
          if (quantity && price) {
            return Math.floor((total + Number(price)) * 100) / 100;
          }
          return Math.floor(total * 100) / 100;
        },
        0
      );

      this.$forceUpdate();
    },
    async search(value: string, key: string): Promise<any> {
      try {
        if (!this.disabled) {
          const items = await (this.$API as any)[key]().liveSearch(
            {
              searchValue: value,
              service_type_id: this.serviceId
            },
            { "x-localization": this.value.order.language }
          );
          return { items };
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    async searchCountry(value: string, key: string): Promise<any> {
      try {
        if (!this.disabled) {
          const params = {
            fields: {
              value: "id",
              text: "name",
              label: "code"
            },
            route: "v1.countries.index.get",
            filter: {
              name: value,
              code: value
            }
          };
          return await (this.$API as any)[key]().liveSearch(params);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    addRow(): void {
      const index = this.goods.length;

      this.selectedCountry[index] = { text: "" };
      this.selectedProduct[index] = { text: "" };
      this.goods.push({});
    },
    deleteRow(index: number): void {
      this.goods.splice(index, 1);
    },
    changeData() {
      this.$emit("input", this.goods);
    },
    setCountryId(item: any, index: number) {
      item.origin_country_id = this.selectedCountry[index].value;
    },
    setGoodId(item: any, index: number) {
      if (this.selectedProduct[index]?.value) {
        item.good_id = this.selectedProduct[index].value;
        item.name = this.selectedProduct[index].text;
      } else {
        if (this.selectedProduct[index].hasOwnProperty("text")) {
          item.name = this.selectedProduct[index].text;
          return;
        }
        item.name = this.selectedProduct[index];
      }
    },
    setPriceCurrency(item: any) {
      item.price =
        Math.ceil(item.price_currency * this.exchangeRate * 1000) / 1000;
      this.getPrices();
    },
    setPrice(item: any) {
      item.price_currency =
        Math.ceil((item.price / this.exchangeRate) * 1000) / 1000;
      this.getPrices();
    },
    setDecimals(item: any) {
      item.price_currency = Number(item.price_currency).toFixed(2);
      item.price = Number(item.price).toFixed(2);
    },
    setLiveSearchValues(): void {
      if (this.isEdit) {
        for (const key in this.goods) {
          this.selectedProduct[key] = {
            value: this.goods[key].good_id,
            text: this.goods[key].name
          };
          this.selectedCountry[key] = {
            value: this.goods[key].origin_country_id,
            text: this.goods[key].origin_country_name
          };
          this.firstLoad = true;
        }
      }
    },
    changeKey() {
      this.forceKey = this.forceKey++;
    },
    async updateComments(event: any) {
      if (event.text) {
        this.comments[event.key] = event;
      } else {
        delete this.comments[event.key];
      }
      this.changeKey();
    }
  }
});
